import React, { FC, FormEvent, useCallback, useState } from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import AccountKeyIcon from 'mdi-react/AccountKeyIcon';
import { loginUser } from '../../context/loginContext';
import { FormattedMessage } from 'react-intl';
import { HelpLink } from '../../components/auth/HelpLink';
import { LoginPage as SEO } from '../../components/SEO';
import { AuthLayout } from '../../components/auth/AuthLayout';
import { Or } from '../../components/Or';
import { Button } from '../../components/Button';
import { borderRadius, Color } from '../../components/Theme';
import { Error } from '../../components/Error';
import { AuthLoginPoll } from '../../components/auth/AuthLoginPoll';

const LargeButton = styled(Button)`
  margin-left: 0 !important;
  padding: 22px !important;
  width: 100%;
  border-radius: ${borderRadius * 3}px !important;
`;

const LargeSubmitButton = styled(LargeButton)`
  margin-top: 32px !important;
`;

const Form = styled.form<{ $polling: boolean }>`
  position: relative;

  ::after {
    content: '';
    display: ${({ $polling }) => ($polling ? 'block' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${Color.disabled};
    border-radius: ${borderRadius}px;
    z-index: 1;
  }
`;

const Login: FC = () => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string | JSX.Element | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleValidate = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      event.stopPropagation();

      setIsLoading(true);
      setError(null);

      const { status, error, token } = await loginUser({ email });
      if (status !== 'ok') {
        setIsLoading(false);
        return setError(error);
      }

      setToken(token as string);
    },
    [loginUser, setError, email]
  );

  return (
    <>
      <SEO />

      <AuthLayout
        title={<FormattedMessage id="register.loginDialog.title" defaultMessage="Kirjaudu sisään" />}
        description={
          <FormattedMessage
            id="register.loginDialog.subtitle"
            defaultMessage="Kirjaudu sisään Constleen sähköpostiin saapuvan kirjautumislinkin avulla."
          />
        }
        icon={<AccountKeyIcon />}
        control={false}
        width={500}
        footer={
          <>
            <HelpLink href="/contact-us">
              <FormattedMessage id="register.dialog.helpLink" defaultMessage="Apua kirjautumiseen" />
            </HelpLink>
          </>
        }
      >
        {!token && (
          <Form $polling={!!token} onSubmit={handleValidate}>
            <TextField
              label={<FormattedMessage id="register.form.email" defaultMessage="Sähköpostiosoite" />}
              variant="outlined"
              type="email"
              autoComplete="username"
              onChange={(event) => setEmail(event.target.value)}
              fullWidth
            />

            <Error error={error} />

            <LargeSubmitButton type="submit" loading={isLoading}>
              <FormattedMessage id="register.loginDialog.loginButton" defaultMessage="Lähetä kirjautumislinkki" />
            </LargeSubmitButton>
            <Or />
            <LargeButton type="submit" color="default" loading={isLoading}>
              <FormattedMessage id="register.dialog.registerLink" defaultMessage="Luo uusi tili & aloita 14-päivän kokeilu" />
            </LargeButton>
          </Form>
        )}

        {!!token && <AuthLoginPoll token={token} />}
      </AuthLayout>
    </>
  );
};

export default Login;
