import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Color } from './Theme';

const Divider = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: ${Color.background};
  }

  > span {
    padding: 0 8px;
    background-color: ${Color.white};
    color: ${Color.disabled};
    z-index: 1;
  }
`;

export const Or: FC = () => {
  return (
    <Divider>
      <span>
        <FormattedMessage id="orDivider" defaultMessage="Tai" />
      </span>
    </Divider>
  );
};
