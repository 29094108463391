import { CSSProperties, FC } from 'react';
import styled, { css } from 'styled-components';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import InfoOutlineIcon from 'mdi-react/InfoOutlineIcon';
import { Color, mediaQuery } from '../../components/Theme';
import Link from 'next/link';
import { lighten } from 'polished';

const Container = styled.div`
  &:last-child a {
    margin-bottom: 0;
  }

  a {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    color: ${Color.white};

    svg:not(:first-child) {
      margin-left: 0.4rem;
    }

    svg:first-child {
      margin-top: 0;
      margin-right: 0.4rem;
    }

    ${mediaQuery.small(css`
      color: ${lighten(0.1, Color.black)};
    `)}
  }
`;

export const HelpLink: FC<{ href: string; style?: CSSProperties }> = ({ href, style = {}, children }) => (
  <Container>
    <Link href={href}>
      <a style={style}>
        <InfoOutlineIcon size={16} color={Color.primary} /> {children} <ArrowRightIcon size={16} />
      </a>
    </Link>
  </Container>
);

export const RegisterContactUsLink = () => <HelpLink href="/contact-us#register">Kysyttävää rekisteröitymisestä? Ota yhteyttä</HelpLink>;

export const IdentificationContactUsLink = () => (
  <HelpLink href="/contact-us#identification">Kysyttävää tunnistautumisesta? Ota yhteyttä</HelpLink>
);

export const CompanyContactUsLink = () => <HelpLink href="/contact-us#company">Kysyttävää yrityksen liittämisestä? Ota yhteyttä</HelpLink>;
